import React from 'react';

function Ideology({ lang }) {
    const data = {
        ru: [
            {
                title: 'Миссия продукта',
                text: ['Наша миссия - помочь стартап сообществу максимально быстро и эффективно проверять идеи и выводить жизнеспособные продукты на рынок.',
                    'Мы хотим предоставить инструменты, которые позволят ускорить процесс создания и тестирования прототипа, чтобы можно было оперативно понять перспективность продукта и сосредоточиться на действительно потенциальных решениях.'],
                image: './image/mission.svg',
            },
            {
                title: 'Проблематика',
                text: ['Основная проблема в том, что сейчас от зарождения идеи до проверки реальной бизнес-гипотезы обычно проходит очень длительное время - месяцы или даже годы.',
                    'При этом статистика показывает, что более 80% стартапов терпят неудачу.',
                    'Это связано с тем, что не все первоначальные предположения подтверждаются при тестировании продукта.',
                    'Таким образом, долгий цикл разработки не позволяет быстро итерировать и отсеивать нежизнеспособные идеи, что ведёт к потере времени и других ресурсов.'],
                image: './image/problem.svg',
            },
            {
                title: 'Решение',
                text: ['Мы предлагаем сервис, который поможет стартапам ускорить процесс создания рабочего прототипа продукта для тестирования гипотез.',
                    'Это позволит быстрее запускать итерации, оценивать реакцию пользователей и понимать перспективы идеи.',
                    'Такой подход позволит стартапам более эффективно расходовать ресурсы и в итоге быстрее выводить на рынок жизнеспособные продукты.'],
                image: './image/succes.svg',
            }
        ],
        en: [
            {
                title: 'Product Mission',
                text: ['Our mission is to help the startup community test ideas as quickly and efficiently as possible and bring viable products to market.',
                    'We want to provide tools that will speed up the process of creating and testing a prototype so that you can quickly understand the prospects of the product and focus on really potential solutions.'],
                image: './image/mission.svg',
            },
            {
                title: 'Problematic',
                text: ['The main problem is that now it usually takes a very long time from the conception of an idea to the verification of a real business hypothesis - months or even years.',
                    'At the same time, statistics show that more than 80% of startups fail.',
                    'This is due to the fact that not all initial assumptions are confirmed when testing the product.',
                    'Thus, a long development cycle does not allow you to quickly iterate and weed out non-viable ideas, which leads to a loss of time and other resources.'],
                image: './image/problem.svg',
            },
            {
                title: 'Solution',
                text: ['We offer a service that will help startups speed up the process of creating a working prototype of a product for testing hypotheses.',
                    'This will allow you to run iterations faster, evaluate user reactions and understand the prospects of the idea.',
                    'This approach will allow startups to spend resources more efficiently and, as a result, bring viable products to market faster.'],
                image: './image/succes.svg',
            }
        ]
    };

    return (
        <div className="Ideology">
            {data[lang].map(item => (
                <div className="IdeoDiv" key={item.title}>
                    <div>
                        <h2>{item.title}</h2>
                        {item.text.map(item => (
                            <p dangerouslySetInnerHTML={{ __html: item }} />
                        ))}
                    </div>
                    <div className="ideoImg" style={{ backgroundImage: `url(${item.path})` }}>
                        <img src={item.image} alt="" />
                    </div>
                </div>
            ))}
        </div>
    );
}

export default Ideology;