import React from 'react';
import { FaDiaspora } from 'react-icons/fa';

function Product({ lang }) {
    const mvp = {
        ru: {
            title: 'Состав <span>Minimum viable Product</span>',
            text: ['Даем простой инструмент генерации простого технологического решения для проверки гипотезы стартапа.',
                'Входные данные — описание идеи стартапа, указание деталей технологического решения, если они есть.',
                'Что делаем мы — генерируем простейшую архитектуру сервиса, скрипты создания инфры, скрипты для CI/CD.',
                'Выходные данные — артефакты необходимые для запуска сервиса + инструкция по шагам, как запустить проект в облаке и получить готовый шаблон сервиса.'],
            image: './image/formula.svg',
        },
        en: {
            title: 'Composition <span>Minimum viable Product</span>',
            text: ['We give a simple tool for generating a simple technological solution to test the startup hypothesis.',
                'Input data — description of the startup idea, indication of the details of the technological solution, if any.',
                'What we do is generate the simplest architecture of the service, scripts for creating infra, scripts for CI/CD.',
                'Output data — artifacts needed to launch the service + instructions on how to launch a project in the cloud and get a ready-made service template.'],
            image: './image/formula.svg',
        }
    };

    const bot = {
        ru: {
            title: 'Возможности бота',
            text: ['Попробуйте демо-версию <span>Platform</span> - инструмента, который ускорит создание и тестирование вашего стартапа.',
                'Опишите <span>Platform</span> свою бизнес-идею. Он сгенерирует базовую техническую архитектуру, инфраструктурный код и <span>CI/CD</span> скрипты.',
                'Это позволит получить рабочий прототип за пару недель для тестирования гипотез. Вы быстрее поймете перспективность продукта и сфокусируетесь на лучших идеях.',
                'Ждем вашей обратной связи для улучшения <span>Platform</span>. Вместе сделаем процесс создания стартапов проще и быстрее.'],
            image: './image/bot.svg',
        },
        en: {
            title: 'Bot Features',
            text: ['Try the demo version of <span>Platform</span>, a tool that will speed up the creation and testing of your startup.',
                'Describe <span>Platform</span> your business idea. It will generate the basic technical architecture, infrastructure code and <span>CI/CD</span> scripts.',
                'This will allow you to get a working prototype in a couple of weeks to test hypotheses. You will quickly understand the prospects of the product and focus on the best ideas.',
                'We are waiting for your feedback to improve the <span>Platform</span>. Together we will make the process of creating startups easier and faster.'],
            image: './image/bot.svg',
        }
    };

    const services = [
        { title: 'Backend', text: ['python', 'nodejs', 'java + spring boot'] },
        { title: 'Databases', text: ['postgresql', 'mongodb', 'mysql'] },
        { title: 'Caching', text: ['Redis', 'Memcached'] }
    ]

    const serviceTitle = {
        ru: { title: 'Поддерживаемые сервисы' },
        en: { title: 'Supported services' }
    }
    return (
        <div className='Product'>
            <div className='mvp'>
                <div>
                    <h2 dangerouslySetInnerHTML={{ __html: mvp[lang].title }} />
                    {mvp[lang].text.map(item => (
                        <p dangerouslySetInnerHTML={{ __html: item }} />
                    ))}
                </div>
                <div className='mvpImage'>
                    <img src={mvp[lang].image} />
                </div>
            </div>
            <div className='services'>
                <h2>{serviceTitle[lang].title}</h2>
                <div className='servBlock'>
                    {services.map((text, index) => (
                        <div>
                            <span>{text.title}</span>
                            <ul>
                                {text.text.map((text, index) =>
                                    <li><FaDiaspora />{text}</li>
                                )}
                            </ul>
                        </div>
                    ))}
                </div>
            </div>
            <div className='bot'>
                <div>
                    {bot[lang].text.map(item => (
                        <p dangerouslySetInnerHTML={{ __html: item }} />
                    ))}                    <a href='https://t.me/DevOpsPlatform_bot'><>Попробовать</></a>
                </div>
                <div>
                    <img src={bot[lang].image} />
                </div>
            </div>
        </div>
    );
}

export default Product;