import React from 'react';

function About({ lang }) {
    const about = {
        ru: {
            title: 'Platform',
            text: ['Platform — <span>dev to dev</span> продукт, который поможет запускать DevOps стартапы.',
                'Он позволит быстро протестировать гипотезу, сгенерировав рабочий прототип.',
                'Вы получите техническое решение в <span>несколько кликов</span> вместо месяцев разработки.',
                'Это сократит время от идеи до запуска, позволит проверить больше гипотез и сфокусироваться на перспективных решениях.',
                'Platform сгенерирует архитектуру, инфраструктурный код и <span>CI/CD</span> скрипты на основе описания идеи.'],
            image: ['./image/atom.svg', './image/solarSystem.svg'],
            path: 'M40.7,-56.8C54.7,-62.5,69.2,-55.4,68.6,-43.8C67.9,-32.2,52.2,-16.1,50.4,-1C48.7,14.1,61.1,28.3,62.5,41.2C63.9,54.1,54.3,65.9,42.1,67.7C29.9,69.5,14.9,61.4,5.9,51.2C-3.1,41,-6.3,28.7,-18.1,26.6C-29.9,24.5,-50.3,32.7,-56.9,29.9C-63.5,27.2,-56.3,13.6,-52.7,2.1C-49.1,-9.4,-49.1,-18.9,-47.7,-30.6C-46.3,-42.3,-43.4,-56.3,-35.2,-53.9C-27.1,-51.5,-13.5,-32.7,-0.1,-32.6C13.4,-32.4,26.8,-51,40.7,-56.8Z'
        },
        en: {
            title: 'Platform',
            text: ['Platform is a <span>dev to dev</span> product that will help launch startups in the field of <span>DevOps</span>.',
                'It will give you the opportunity to quickly test a hypothesis by generating a working prototype of the service. You will get a technical solution in <span>a few clicks</span> instead of months of development.',
                'This will reduce the time from idea to product launch, test more hypotheses and focus on truly promising solutions.',
                'Platform will generate the service architecture, infrastructure code and <span>CI/CD</span> scripts based on your idea description.',
                'If you have any suggestions for improving the Platform for launching startups, write in the chat of our community.'],
            image: ['./image/atom.svg', './image/solarSystem.svg'],
            path: 'M40.7,-56.8C54.7,-62.5,69.2,-55.4,68.6,-43.8C67.9,-32.2,52.2,-16.1,50.4,-1C48.7,14.1,61.1,28.3,62.5,41.2C63.9,54.1,54.3,65.9,42.1,67.7C29.9,69.5,14.9,61.4,5.9,51.2C-3.1,41,-6.3,28.7,-18.1,26.6C-29.9,24.5,-50.3,32.7,-56.9,29.9C-63.5,27.2,-56.3,13.6,-52.7,2.1C-49.1,-9.4,-49.1,-18.9,-47.7,-30.6C-46.3,-42.3,-43.4,-56.3,-35.2,-53.9C-27.1,-51.5,-13.5,-32.7,-0.1,-32.6C13.4,-32.4,26.8,-51,40.7,-56.8Z'
        }
    }


    return (
        <>
            <div className='About'>
                <div className='cosmoImg'>
                    <img src={about[lang].image[1]} />
                </div>
                <div className='aboutText'>
                    <h2 dangerouslySetInnerHTML={{ __html: about[lang].title }} />
                    {about[lang].text.map(item => (
                        <p dangerouslySetInnerHTML={{ __html: item }} />
                    ))}
                </div>
                <div className='atomImg'>
                    <img src={about[lang].image[0]} />
                </div>
            </div>
        </>
    );
}

export default About;