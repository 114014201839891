import React, { useState } from 'react';
import { FiMenu, FiX } from 'react-icons/fi';
import { FaTelegramPlane, FaDiscord } from "react-icons/fa";
import { Routes, Route, Link, Navigate } from "react-router-dom";
import Ideology from "../Page/Ideology";
import Product from "../Page/Product";
import About from '../Page/About';
import Instruction from '../Page/Instruction';

function Header() {
    const [lang, setLang] = useState('ru');

    const handleLangChange = (newLang) => {
        setLang(newLang);
    }

    const handleLangClick = (lang) => {
        handleLangChange(lang);
    }

    const [isFormOpen, setIsFormOpen] = useState(false);

    const toggleMenu = (event) => {
        event.preventDefault();
        setIsFormOpen(!isFormOpen);
    };

    const langs = {
        ru: {
            ideo: 'Идеология',
            prod: 'Продукт',
            insrt: 'Инструкция'
        },
        en: {
            ideo: 'Ideology',
            prod: 'Product',
            insrt: 'Instruction'
        },
    }

    return (
        <>
            <header>
                <div className='navLogo'>
                    <Link to="/">
                        <img src='./image/logo.png' />
                    </Link>
                </div>
                <ul className='nav-list'>
                    <li className='nav-item'><Link className='nav-link' to="/product">{langs[lang].prod}</Link></li>
                    <li className='nav-item'><Link className='nav-link' to="/ideology">{langs[lang].ideo}</Link></li>
                    <li className='nav-item'><Link className='nav-link' to="/instruction">{langs[lang].insrt}</Link></li>
                    <div className='nav-lang'>
                        <div className={lang === 'ru' ? 'active' : ''} onClick={() => handleLangClick('ru')}>
                            <p value='ru'>RU</p>
                        </div>
                        <span></span>
                        <div className={lang === 'en' ? 'active' : ''} onClick={() => handleLangClick('en')}>
                            <p value='ru'>EN</p>
                        </div>
                    </div>
                </ul>
                <ul className="nav">
                    <li><a href="https://discord.com/channels/1109396222604738612/1110871255256678402"><div className='circle'><FaDiscord className='logo' /></div></a></li>
                    <li><a href="https://t.me/DevOpsPlatform_bot"><div className='circle'><FaTelegramPlane className='logo' /></div></a></li>
                    <li onClick={toggleMenu}><div className='square'>{isFormOpen ? (
                        <FiX className='logo' />
                    ) : (
                        <FiMenu className='logo' />
                    )}</div></li>
                </ul>
            </header>
            <Routes>
                <Route path="/" element={<About lang={lang} />} />
                <Route path="/ideology" element={<Ideology lang={lang} />} />
                <Route path="/product" element={<Product lang={lang} />} />
                <Route path="/instruction" element={<Instruction lang={lang} />} />
                <Route path="*" element={<Navigate replace to="/" />} />
            </Routes>
            {isFormOpen && (
                <div className='burger'>
                    <div className='square' onClick={toggleMenu}>{isFormOpen ? (
                        <FiX className='logo' />
                    ) : (
                        <FiMenu className='logo' />
                    )}</div>
                    <ul className='nav-list'>
                        <div className='nav-lang'>
                            <div className={lang === 'ru' ? 'active' : ''} onClick={() => handleLangClick('ru')}>
                                <p value='ru'>RU</p>
                            </div>
                            <span></span>
                            <div className={lang === 'en' ? 'active' : ''} onClick={() => handleLangClick('en')}>
                                <p value='ru'>EN</p>
                            </div>
                        </div>
                        <li className='nav-item'><Link className='nav-link' to="/product">{langs[lang].prod}</Link></li>
                        <li className='nav-item'><Link className='nav-link' to="/ideology">{langs[lang].ideo}</Link></li>
                        <li className='nav-item'><Link className='nav-link' to="/instruction">{langs[lang].insrt}</Link></li>
                    </ul>
                </div>
            )}
        </>
    );
}
export default Header;


