import React, { useState } from 'react';

function Instruction({ lang }) {
    const data = {
        ru: [
            {
                text: 'Начинаем с команды <span>/startPoll</span> и выбираем язык',
                img: './image/rusInstr1.png'
            },
            {
                text: 'Описываем <span>общую концепцию</span> и <span>задачу</span> вашего приложения',
                img: './image/rusInstr2.png'
            },
            {
                text: 'Выбираем один из предложенных <span>облачных провайдеров</span>',
                img: './image/rusInstr3.png'
            },
            {
                text: 'Выбираем <span>CI/CD</span> инструмент',
                img: './image/rusInstr4.png'
            },
            {
                text: 'Выбираем дополнительный сервис: <span>базу данных</span>, кеширование в виде <span>Redis</span>, или <span>S3-хранилище</span>',
                img: './image/rusInstr5.png'
            },
            {
                text: 'Выбираем один из предложенных <span>дополнительных сервисов, в данном случае <span>базу данных</span>',
                img: './image/rusInstr6.png'
            },
            {
                text: 'Подтверждаем конфигурацию',
                img: './image/rusInstr7.png'
            }
        ],
        en: [
            {
                text: 'We start with the command <span>/startPoll</span> and select the language',
                img: './image/engInstr1.png'
            },
            {
                text: 'Describing the <span>general concept</span> and <span>task</span> of your application',
                img: './image/engInstr2.png'
            },
            {
                text: 'Choosing one of the suggested <span>cloud providers</span>',
                img: './image/engInstr3.png'
            },
            {
                text: 'Select <span>CI/CD</span> tool',
                img: './image/engInstr4.png'
            },
            {
                text: 'Selecting an additional service: <span>database</span>, caching in the form of <span>Redis</span>, or <span>S3 storage</span>',
                img: './image/engInstr5.png'
            },
            {
                text: 'Choose one of the suggested <span>additional services, in this case <span>database</span>',
                img: './image/engInstr6.png'
            },
            {
                text: 'Confirming the configuration',
                img: './image/engInstr7.png'
            }
        ]
    }
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [selectedInstruct, setSelectedInstruct] = useState(data[lang][0]);
    const handleInstructClick = (index) => {
        setSelectedIndex(index);
        setSelectedInstruct(data[lang][index]);
    }
    return (
        <div className='Instruction'>
            <div className='InstructionImg'>
                {selectedInstruct && (
                    <img src={selectedInstruct.img} />
                )}
            </div>

            <div className='instructionDiv'>
                {data[lang].map((item, index) => (
                    <div
                        className={index === selectedIndex ? 'active' : ''}
                        onClick={() => handleInstructClick(index)}><span className='numInstruction'>{index + 1}</span><p dangerouslySetInnerHTML={{ __html: item.text }} /> </div>
                ))}
            </div>

            <div className='instructionDivMobile'>
                <div className='numInstructionMobile'>
                    {data[lang].map((item, index) => (
                        <span className={index === selectedIndex ? 'active' : ''} onClick={() => handleInstructClick(index)}>{index + 1}</span>
                    ))}
                </div>
                {selectedInstruct && (
                    <p dangerouslySetInnerHTML={{ __html: selectedInstruct.text }} />
                )}
            </div>
        </div>
    );
}

export default Instruction;